import React from "react";
import { Link } from "react-router-dom";
import $ from "jquery";

class Navbar extends React.Component {
  componentDidMount() {
    if ($(".main-menu li.dropdown ul").length) {
      $(".main-menu li.dropdown").append('<div class="dropdown-btn"></div>');

      //Dropdown Button
      $(".main-menu li.dropdown .dropdown-btn").on("click", function () {
        $(this).prev("ul").slideToggle(500);
      });
    }
  }
  render() {
    return (
      <div>
        <section className="top-bar-style1">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="top-style1 clearfix">
                  <div className="top-left-style1 float-left clearfix">
                    <ul className="top-contact-info fix">
                      <li>
                        <span className="flaticon-auricular-phone-symbol-in-a-circle"></span>
                        Call 0795223223
                      </li>
                    </ul>
                  </div>
                  <div className="top-right-style1 float-right">
                    <ul className="sociallinks-style-one float-left fix">
                      <li>
                        <a href="#">
                          <i className="fa fa-facebook" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa fa-twitter" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa fa-skype" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa fa-linkedin" aria-hidden="true"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <header className="main-header stricky">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="main-box">
                  <div className="inner-container clearfix">
                    <div className="logo-box">
                      <Link to="/">
                        <img src="images/resources/logo.png" alt="" />
                      </Link>
                    </div>
                    <div className="nav-outer clearfix">
                      <nav className="main-menu clearfix">
                        <div className="navbar-header clearfix">
                          <button
                            type="button"
                            className="navbar-toggle"
                            data-toggle="collapse"
                            data-target=".navbar-collapse"
                          >
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                          </button>
                        </div>
                        <div className="navbar-collapse collapse clearfix">
                          <ul className="navigation clearfix">
                            <li className="">
                              <Link to="/">Home</Link>
                            </li>

                            <li className="">
                              <Link to="/aboutus">About</Link>
                            </li>

                            <li className="dropdown">
                              <Link to="/services">Services</Link>
                              <ul>
                                <li>
                                  <Link to="/iot-solutions">IOT Solutions</Link>
                                </li>

                                <li>
                                  <Link to="/security-solutions">
                                    Security Solutions
                                  </Link>
                                </li>

                                <li>
                                  <Link to="/cloud-solutions">
                                    Cloud Solutions
                                  </Link>
                                </li>
                              </ul>
                            </li>

                            <li>
                              <Link to="/packages">Packages</Link>
                            </li>

                            <li>
                              <Link to="/blog">Blog</Link>
                            </li>

                            <li>
                              <Link to="/contact">Contact</Link>
                            </li>
                          </ul>
                        </div>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default Navbar;

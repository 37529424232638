import React from "react";
import Footer from "../footer/Footer";
import "./vm.css";
import "animate.css";
import Navbar from "../navbar/Navbar";

class Aboutus extends React.Component {
  render() {
    return (
      <div>
        <Navbar />
        {/* breadcrumb */}
        <section
          className="breadcrumb-area"
          style={{
            backgroundImage: "url(/images/about/about_breadcrumb.jpeg)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="inner-content text-center clearfix">
                  <div className="title animate__animated animate__zoomIn">
                    <h1>About Us</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* end upload breadcrumb */}

        {/* about area */}
        <section className="choose-area secpd1">
          <div className="container">
            <div className="row">
              <div className="col-xl-6">
                <div className="choose-content-box">
                  <div className="sec-title animate__animated animate__slideInLeft">
                    <h6>Why Tai Fibre Connect?</h6>
                    <div className="title">About Us</div>
                  </div>
                  <div className="inner-content animate__animated animate__slideInUp">
                    <div className="text">
                      <p>
                        We provide Internet services to both homes and
                        businesses with a future plan of implementing I.O.T. Our
                        main key is customer satisfaction, as well as providing
                        value for money to our customers.
                      </p>
                    </div>
                    <ul>
                      <li className="animate__animated animate__fadeInLeft">
                        <h1>99.98%</h1>
                        <span>Reliable</span>
                      </li>
                      <li className="animate__animated animate__fadeInRight">
                        <h1>2X</h1>
                        <span>Faster</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="choose-image-box animate__animated animate__slideInRight">
                  <img src="/images/about/about_us.jpeg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* end about area */}

        {/* vision, mission */}
        <section className="offering-area2">
          <div className="container  animate__animated animate__fadeInUp">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="single-offering-box2">
                  <span className="fa fa-eye"></span>
                  <h4>Vision</h4>
                  <p>Our vision is to be the leading company in the provision of
                    information and technology solutions in Africa</p>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="single-offering-box2">
                  <span className="fa fa-rocket"></span>
                  <h4>Mission</h4>
                  <p>To improve businesses and peoples’ lifestyles through
                    information and appropriate technological solutions.</p>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="single-offering-box2">
                  <span className="fa fa-bullseye"></span>
                  <h4>Goal</h4>
                  <p>To provide Internet Services in Kenya to match International
                    standards in both rural and urban areas.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* end vision, mission */}

        {/* counter */}
        <section
          className="fact-counter-area"
          style={{ backgroundImage: "url(/images/about/about_counter.jpeg)" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <ul className="clearfix">
                  <li
                    className="single-fact-counter text-center wow fadeInUp"
                    data-wow-delay="100ms"
                  >
                    <div className="count-box">
                      <h1>
                        <span
                          className="timer"
                          data-from="1"
                          data-to="705"
                          data-speed="5000"
                          data-refresh-interval="50"
                        >
                          705
                        </span>
                      </h1>
                      <div className="title">
                        <h4>Connections provided</h4>
                      </div>
                    </div>
                  </li>

                  <li
                    className="single-fact-counter text-center wow fadeInUp"
                    data-wow-delay="200ms"
                  >
                    <div className="count-box">
                      <h1>
                        <span
                          className="timer"
                          data-from="1"
                          data-to="43"
                          data-speed="5000"
                          data-refresh-interval="50"
                        >
                          43
                        </span>
                      </h1>
                      <div className="title">
                        <h4>Areas Covered</h4>
                      </div>
                    </div>
                  </li>

                  <li
                    className="single-fact-counter text-center wow fadeInUp"
                    data-wow-delay="300ms"
                  >
                    <div className="count-box">
                      <h1>
                        <span
                          className="timer"
                          data-from="1"
                          data-to="96"
                          data-speed="5000"
                          data-refresh-interval="50"
                        >
                          96
                        </span>
                      </h1>
                      <div className="title">
                        <h4>tv channels</h4>
                      </div>
                    </div>
                  </li>

                  <li
                    className="single-fact-counter text-center wow fadeInUp"
                    data-wow-delay="400ms"
                  >
                    <div className="count-box">
                      <h1>
                        <span
                          className="timer"
                          data-from="1"
                          data-to="694"
                          data-speed="5000"
                          data-refresh-interval="50"
                        >
                          694
                        </span>
                      </h1>
                      <div className="title">
                        <h4>Happy Customers</h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* end counter */}

        {/* core values */}
        <section className="choose-area2 secpd1">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 animate__animated animate__fadeInLeft">
                <div className="">
                  <div className="sec-title">
                    <h6>Corporate Culture</h6>
                    <div className="title">Core Values</div>
                  </div>
                  <div className="inner-content">
                    <div className="text">
                      <p>
                        Our success is built on the entrepreneurial and striving
                        spirit and enduring focus on meeting the customer needs
                        and satisfaction.
                      </p>
                    </div>
                    <ul>
                      <li>Honesty and Transparency.</li>
                      <li>Reliability and Responsibility.</li>
                      <li>Discipline and Perseverance.</li>
                      <li>Teamwork.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="choose-image-box animate__animated animate__fadeInRight">
                  <img src="/images/about/about_core_values.jpeg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* end core values */}

        <Footer />
      </div>
    );
  }
}

export default Aboutus;

import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React from "react";
import Home from "./components/home/Home"
import About from "./components/aboutus/Aboutus"
import Contact from "./components/contact/Contact"
import Services from "./components/services/Services"
import IOT from "./components/iot/Iot"
import Security from "./components/security/Security"
import Cloud from "./components/cloud/cloud"
import Packages from "./components/packages/Packages"
import Blog from "./components/blog/Blog"
import ScrollToTop from "./components/scroll/ScrollToTop"

class App extends React.Component {
  
  render() {
    
    return (
      <div>
        <Router>
          <div>
            <ScrollToTop />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/aboutus" component={About} />
              <Route exact path="/services" component={Services} />
              <Route exact path="/packages" component={Packages} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/blog" component={Blog} />
              <Route exact path="/iot-solutions" component={IOT} />
              <Route exact path="/security-solutions" component={Security} />
              <Route exact path="/cloud-solutions" component={Cloud} />
            </Switch>
            
          </div>
        </Router>
      </div>
    );
  }
}

export default App;

import React from "react";
import Footer from "../footer/Footer";
import "animate.css";
import Navbar from "../navbar/Navbar";

class Contact extends React.Component {
  render() {
    return (
      <div>
        <Navbar />
        {/* breadcrumb */}
        <section
          className="breadcrumb-area"
          style={{
            backgroundImage: "url(/images/contact/contact.jpeg)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="inner-content text-center clearfix">
                  <div className="title animate__animated animate__zoomIn">
                    <h1>Contact</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* end breadcrumb */}

        {/* contact info */}
        <section className="contact-info-area">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <div className="single-info-box text-center animate__animated animate__fadeInUp">
                  <div className="title-holder">
                    <span>Call</span>
                    <h3>Phone</h3>
                  </div>
                  <ul className="icon-holder">
                    <li>
                      <span className="flaticon-call clr3"></span>
                    </li>
                  </ul>
                  <ul className="text">
                    <li>
                      <a href="tel:+254 795 223 223"> +254 795 223 223</a>
                    </li>
                    <br />
                  </ul>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <div className="single-info-box text-center animate__animated animate__fadeInUp">
                  <div className="title-holder">
                    <span>visit us</span>
                    <h3>Our Address</h3>
                  </div>
                  <ul className="icon-holder">
                    <li>
                      <span className="flaticon-fast clr2"></span>
                    </li>
                  </ul>
                  <ul className="text">
                    <li>P.O. BOX 22040-00100</li>
                    <li>Varsity Ville, Kenya</li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <div className="single-info-box text-center animate__animated animate__fadeInUp">
                  <div className="title-holder">
                    <span>email</span>
                    <h3>Email</h3>
                  </div>
                  <ul className="icon-holder">
                    <li>
                      <span className="flaticon-message"></span>
                    </li>
                  </ul>
                  <ul className="text">
                    <li>
                      <a href="mailto:info@hmiwirelessltd.com">
                        info@hmiwirelessltd.com
                      </a>
                    </li>
                    <br />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* end contact info */}

        {/* maps */}
        <section className="google-map-area">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15955.945563696865!2d36.95473552670932!3d-1.1700700363445575!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f4098156c0543%3A0xa9688a79915d27e7!2sVarsityville%20Estate!5e0!3m2!1sen!2ske!4v1637817716996!5m2!1sen!2ske"
                  width="100%"
                  height="450"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  loading="lazy"
                  title="tai location"
                ></iframe>
              </div>
            </div>
          </div>
        </section>
        {/* end maps */}

        <Footer />
      </div>
    );
  }
}

export default Contact;

import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      {/* Start footer area */}
      <footer className="footer-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="single-footer-widget marbtm50">
                <div className="footer-logo">
                  <img
                    src="images/resources/footer-logo.png"
                    alt="Awesome Logo"
                  />
                </div>
                <div className="company-info">
                  <p>Beyond Connectivity.</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="single-footer-widget marbtm50">
                <div className="title">
                  <h3>Company</h3>
                </div>
                <ul className="page-links">
                  <li>
                    <Link to="/aboutus">About</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>
                    <Link to="/packages">Packages</Link>
                  </li>
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="single-footer-widget marbtm50">
                <div className="title">
                  <h3>Services</h3>
                </div>
                <ul className="services-links">
                  <li>
                    <Link to="/iot-solutions">Internet of Things</Link>
                  </li>

                  <li>
                    <Link to="/security-solutions">Security Solutions</Link>
                  </li>

                  <li>
                    <Link to="/cloud-solutions">Cloud Solutions</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* End footer area */}

      {/* footer bottom */}
      <section className="footer-bottom-area">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div className="inner clearfix">
                <div className="single copyright-text">
                  <p>© Copyright Tai Fibre 2021 All Rights Reserved</p>
                </div>
                <div className="single footer-bottom-contact-info">
                  <ul className="left">
                    <li>
                      <i className="fa fa-envelope" aria-hidden="true"></i>
                      <a href="mailto:info@hmiwirelessltd.com">
                        info@hmiwirelessltd.com
                      </a>
                    </li>
                    <li>
                      <i className="fa fa-phone-square" aria-hidden="true"></i>
                      <a href="tel:+254 795 223 223">+254 795 223 223</a>
                    </li>
                  </ul>
                </div>
                <div className="single footer-bottom-contact-info">
                  <ul className="right">
                    <li>P.O. BOX 22040-00100</li>
                    <li>Varsity Ville, Kenya</li>
                  </ul>
                </div>
                <div className="single footer-social-links">
                  <ul className="sociallinks-style-two fix">
                    <li
                      className="wow slideInUp"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <a href="#">
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li
                      className="wow slideInUp"
                      data-wow-delay="200ms"
                      data-wow-duration="1500ms"
                    >
                      <a href="#">
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li
                      className="wow slideInUp"
                      data-wow-delay="400ms"
                      data-wow-duration="1500ms"
                    >
                      <a href="#">
                        <i className="fa fa-skype" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li
                      className="wow slideInUp"
                      data-wow-delay="600ms"
                      data-wow-duration="1500ms"
                    >
                      <a href="#">
                        <i className="fa fa-linkedin" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end footer bottom */}
    </div>
  );
}

export default Footer;

import React from "react";
import Footer from "../footer/Footer";
import Landing from "./Landing";
import OwlCarousel from "react-owl-carousel";
import Pricingtable from "../pricingtable/Pricingtable";
import { Link } from "react-router-dom";
import "animate.css";
import "./layout.css";
import Navbar from "../navbar/Navbar";
import Faq from "../faq/Faq"

class Home extends React.Component {

  render() {
    return (
      <div>
        <Navbar />
        <Landing />
        {/* featured area */}
        <section className="featured-area">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <ul className="featured-box">
                  <li className="single-featured-box animate__animated animate__fadeInLeft">
                    <div className="icon-holder">
                      <span className="fa fa-bolt"></span>
                    </div>
                    <div className="title-holder">
                      <h3>Fast</h3>
                      <span>Ultra fast, ultra reliable</span>
                    </div>
                  </li>

                  <li className="single-featured-box animate__animated animate__fadeInDown">
                    <div className="icon-holder">
                      <span className="flaticon-support"></span>
                    </div>
                    <div className="title-holder">
                      <h3>Customer Experience</h3>
                      <span>Get 24/7 support</span>
                    </div>
                  </li>

                  <li className="single-featured-box animate__animated animate__fadeInRight">
                    <div className="icon-holder">
                      <span className="flaticon-download-from-the-cloud"></span>
                    </div>
                    <div className="title-holder">
                      <h3>Unlimited Downloads</h3>
                      <span>Unlimited broadband with no usage limits</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* end featured area */}

        {/* welcome section */}
        <section className="home-area secpd1" id="home-about">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-12">
                <div className="faq-image-box clearfix animate__animated animate__zoomInLeft">
                  <div className="">
                    <img src="images/home/fam.jpeg" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-12">
                <div className="welcome-content-box">
                  <div className="sec-title animate__animated animate__slideInRight">
                    <h6>Welcome to Tai Fiber Connect</h6>
                    <div className="title">
                      Unbelievable Super Fast
                      <br /> Fibre
                    </div>
                  </div>
                  <div className="inner-content">
                    <div className="text animate__animated animate__slideInRight">
                      <p>
                        Tai fiber connect fibre deals are in a league of their
                        own. Our fastest widely available average broadband
                        speeds are 3x quicker than other broadband providers.
                        Our broadband comes with Web Safe included, giving you
                        the option to block access to unsuitable or potentially
                        fraudulent sites.
                      </p>
                    </div>

                    <div className="button animate__animated animate__zoomInRight">
                      <a className="btn-one" href="#pricing-area">
                        Discover More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* end welcome section */}

        {/* pricing section */}
        <Pricingtable />
        {/* end pricing section */}

        {/* testimonials */}
        <section className="testimonial-area">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="testimonial-box">
                  <div className="testimonial-text">
                    <p>Testimonials</p>
                  </div>
                  <OwlCarousel
                    items={1}
                    margin={30}
                    autoplay={true}
                    loop={true}
                    startPosition={0}
                    smartSpeed={1000}
                    className="testimonial-carousel owl-carousel owl-theme"
                  >
                    <div className="single-testimonial-item text-center">
                      <div className="image-box">
                        <img
                          src="/images/testimonial/female_avatar.png"
                          alt=""
                        />
                        <div className="icon">
                          <span className="flaticon-quote"></span>
                        </div>
                      </div>
                      <div className="text-box">
                        <p>
                          I have been a client of Tai fiber connect for a few months
                          now. They provide excellent service at an amazingly
                          great price.
                        </p>
                      </div>
                      <div className="client-name">
                        <h5>Susan Wairimu</h5>
                      </div>
                    </div>

                    <div className="single-testimonial-item text-center">
                      <div className="image-box">
                        <img src="/images/testimonial/male_avatar.png" alt="" />
                        <div className="icon">
                          <span className="flaticon-quote"></span>
                        </div>
                      </div>
                      <div className="text-box">
                        <p>
                          I was a little sceptical at first, but love it. Much
                          faster than my old service provider and less expensive
                          also.
                        </p>
                      </div>
                      <div className="client-name">
                        <h5>Moses Wachana</h5>
                      </div>
                    </div>

                    <div className="single-testimonial-item text-center">
                      <div className="image-box">
                        <img
                          src="/images/testimonial/female_avatar.png"
                          alt=""
                        />
                        <div className="icon">
                          <span className="flaticon-quote"></span>
                        </div>
                      </div>
                      <div className="text-box">
                        <p>
                          Tai fibre connect is by far the best internet service that we
                          have ever used. The NOC team is always courteous,
                          helpful and respectful when I call.
                        </p>
                      </div>
                      <div className="client-name">
                        <h5>Sarah Mwangi</h5>
                      </div>
                    </div>

                    <div className="single-testimonial-item text-center">
                      <div className="image-box">
                        <img src="/images/testimonial/male_avatar.png" alt="" />
                        <div className="icon">
                          <span className="flaticon-quote"></span>
                        </div>
                      </div>
                      <div className="text-box">
                        <p>
                          Tai fibre connect has been there for us since day one. We have
                          been very satisfied with the fast reliable service and
                          very little if any down time.
                        </p>
                      </div>
                      <div className="client-name">
                        <h5>James Makau</h5>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* end testimonials */}

        <section
          class="slogan-area-style2 text-center"
          style={{ backgroundImage: "url(/images/home/customer_service.jpg)" }}
        >
          <div class="container">
            <div class="row">
              <div class="col-xl-12">
                <div class="inner-content fix ">
                  <div class="icon-holder">
                    <div>
                      <span class="flaticon-modem"></span>
                    </div>
                  </div>
                  <h3>New to Tai Fibre Connect?</h3>
                  <div class="title">
                    <h1>
                      Speak to Our NOC team and We'll Help you Choose the
                      Internet Package to Suit Your Needs
                    </h1>
                  </div>
                  <div className="slide-content left-slide">
                    <div className="button animate__animated animate__fadeInLeft">
                      <Link to="/contact">
                        <div className="btn-one">Contact Us</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* faq */}
        <Faq/>
        {/* end faq */}

        <section class="discover-area">
          <div class="discover">
            <div
              class="image-box wow fadeInLeft"
              data-wow-delay="0ms"
              data-wow-duration="1500ms"
            >
              <img src="images/home/fw_tv.jpg" alt="" />
            </div>
            <div
              class="title-box wow fadeInLeft"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div class="bg-image">
                <img src="images/resources/discover-bg-1.jpg" alt="" />
                <div class="overlay">
                  <div class="inner">
                    <div class="title">
                      <h1>
                        Fantastic
                        <br /> Broadband
                        <br /> Deals
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="image-box wow fadeInRight"
              data-wow-delay="0ms"
              data-wow-duration="1500ms"
            >
              <img src="images/home/family_wifi.jpg" alt="" />
            </div>
            <div
              class="title-box wow fadeInRight"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div class="bg-image">
                <img src="images/resources/discover-bg-2.jpg" alt="" />
                <div class="overlay">
                  <div class="inner">
                    <div class="title">
                      <h1>
                        Get Unlimited
                        <br /> Fiber Extra
                        <br /> Package
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}

export default Home;

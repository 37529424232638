import React from "react";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";


class Cloud extends React.Component {
  render() {
    return (
      <div>
        <Navbar />
        {/* about breadcrumb */}
        <section
          className="breadcrumb-area"
          style={{
            backgroundImage: "url(/images/services/cloud_solutions.jpg)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="inner-content text-center clearfix">
                  <div className="title">
                    <h1>Cloud Solutions</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* end upload breadcrumb */}

        {/* cloud */}
        <section className="welcome-area secpd1">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="choose-image-box">
                  <img
                    src="/images/services/cloud/email_services.jpg"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="">
                  <div className="sec-title">
                    <div className="title">Email Services</div>
                  </div>
                  <div className="inner-content">
                    <div className="text">
                      <p>
                        Our Mail's data centers have top-notch security and
                        surveillance, with a reliable 99.9% uptime.
                      </p>
                      <p>
                        Benefit from secure email hosting that supports
                        encryption both at rest and end-to-end, along with
                        S/MIME message encryption.
                      </p>
                      <span>Solutions</span>
                      <ul>
                        <li>G-Suite.</li>
                        <li>Email migration services.</li>
                        <li>Secure email filtering services.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* end cloud */}

        {/* web hosting */}
        <section className="welcome-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="">
                            <div className="sec-title">
                                <div className="title">Web Hosting</div>
                            </div>
                            <div className="inner-content">
                                <div className="text">
                                    <p>Our cloud hosting platform takes away the complexities of setting up your website
                                        and allows
                                        you to go live within a short
                                        period of time. We help agencies and businesses to build and manage web apps
                                        more
                                        collaboratively and efficiently.</p>
                                    <span>Our plans</span>
                                    <ul>
                                        <li>Unlimited database.</li>
                                        <li>Domain registration.</li>
                                        <li>Free SSL certificates.</li>
                                        <li>Wordpress hosting</li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="choose-image-box">
                            <img src="/images/services/cloud/web_hosting.jpg"
                                alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* end web hosting */}

        {/* Draas */}
        <section className="welcome-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="choose-image-box">
                            <img src="/images/services/cloud/draas.jpg"
                                alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="">
                            <div className="sec-title">
                                
                                <div className="title">Disaster Recovery as a Servive</div>
                            </div>
                            <div className="inner-content">
                                <div className="text">
                                    <p>Tai Fibre reduces the risk of unplanned downtime by securely replicating critical
                                        systems to
                                        the cloud, providing an up-to-date copy
                                        for immediate failover.</p>
                                    <p>For businesses that require a more hands-on approach, Kozi Disaster Recovery
                                        service includes
                                        deployment and initial setup of our software,
                                        plus ongoing management and validation that the disaster recovery solution is
                                        operational.
                                    </p>
                                    <span>Features</span>
                                    <ul>
                                        <li>Recovery times and recovery points of minutes and seconds.</li>
                                        <li>Bandwidth-optimized for limited network impact.</li>
                                        <li>Automated discovery of systems in your environment.</li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* End Draas */}

        <Footer/>
      </div>
    );
  }
}

export default Cloud;

import React from "react";
import Footer from "../footer/Footer";
import { Link } from "react-router-dom";
import "animate.css";
import Navbar from "../navbar/Navbar";

class Services extends React.Component {
  render() {
    return (
      <div>
        <Navbar />
        {/* breadcrumb */}
        <section
          className="breadcrumb-area"
          style={{
            backgroundImage: "url(/images/services/services_broadband.jpeg)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="inner-content text-center clearfix">
                  <div className="title animate__animated animate__zoomIn">
                    <h1>Services</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* end breadcrumb */}

        {/* services */}
        <section className="services-style2-area">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 animate__animated animate__fadeInLeft">
                <div className="single-service-style2 text-center">
                  <div className="img-holder">
                    <img src="/images/services/internet_solutions.jpg" alt="" />
                  </div>
                  <div className="text-holder">
                    <h3>Internet Services</h3>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 animate__animated animate__fadeInUp">
                <Link to="/iot-solutions">
                  <div className="single-service-style2 text-center">
                    <div className="img-holder">
                      <img src="/images/services/Iot_solutions.jpeg" alt="" />
                    </div>
                    <div className="text-holder">
                      <h3>Internet of Things</h3>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 animate__animated animate__fadeInDown">
                <Link to="/security-solutions">
                  <div className="single-service-style2 text-center">
                    <div className="img-holder">
                      <img
                        src="/images/services/security_solutions.jpg"
                        alt=""
                      />
                    </div>
                    <div className="text-holder">
                      <h3>Security Solutions</h3>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 animate__animated animate__fadeInRight">
                <Link to="/cloud-solutions">
                  <div className="single-service-style2 text-center">
                    <div className="img-holder">
                      <img src="/images/services/cloud_solutions.jpg" alt="" />
                    </div>
                    <div className="text-holder">
                      <h3>Cloud Solutions</h3>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
        {/* end services */}

        {/* slogan */}
        <section
          className="slogan-area text-center"
          style={{
            backgroundImage: "url(/images/services/services_slogan.jpeg)",
          }}
        >
          <div className="container">
            <div className="row animate__animated animate__fadeInUp">
              <div className="col-xl-12">
                <div className="inner-content fix">
                  <div className="title">
                    <h1>
                      When it Comes to Switching,
                      <br /> We're Fast. Superfast.
                    </h1>
                  </div>
                  <div className="button">
                    <Link to="/contact">
                      <div className="btn-one">Switch to Tai Fibre Connect</div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* end slogan */}

        <Footer />
      </div>
    );
  }
}

export default Services;

import React from "react";
import $ from "jquery";

class Faq extends React.Component {
  componentDidMount() {
    if ($(".accordion-box").length) {
      $(".accordion-box").on("click", ".accord-btn", function () {
        if ($(this).hasClass("active") !== true) {
          $(".accordion .accord-btn").removeClass("active");
        }

        if ($(this).next(".accord-content").is(":visible")) {
          $(this).removeClass("active");
          $(this).next(".accord-content").slideUp(500);
        } else {
          $(this).addClass("active");
          $(".accordion .accord-content").slideUp(500);
          $(this).next(".accord-content").slideDown(500);
        }
      });
    }
  }
  render() {
    return (
      <div>
        <section className="faq-content-area">
          <div className="container">
            <div className="sec-title text-center">
              <h6>frequently asked question</h6>
              <div className="title">General Inquiries</div>
            </div>
            <div className="row">
              <div className="col-xl-6  animate__animated animate__zoomIn">
                <div className="faq-image-box">
                  <img src="/images/home/faq_pic.jpg" alt="" />
                </div>
              </div>
              <div className="col-xl-6  animate__animated animate__fadeInRight">
                <div className="faq-content-box">
                  <div className="accordion-box">
                    <div
                      className="accordion accordion-block wow fadeInUp"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="accord-btn active">
                        <h4>What is Tai Fibre Connect?</h4>
                      </div>
                      <div className="accord-content collapsed">
                        <p>
                          Tai fibre connect is a service that offers you access
                          to unlimited internet access from the comfort of your
                          home or office.
                        </p>
                      </div>
                    </div>

                    <div
                      className="accordion accordion-block wow fadeInUp"
                      data-wow-delay="100ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="accord-btn">
                        <h4>How do I subscribe to Tai Fibre Connect?</h4>
                      </div>
                      <div className="accord-content">
                        <p>
                          Call +254795223223, one of our representatives will be
                          in touch and book you for installation.
                        </p>
                      </div>
                    </div>

                    <div
                      className="accordion accordion-block wow fadeInUp"
                      data-wow-delay="200ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="accord-btn">
                        <h4>How do I pay for my subscription?</h4>
                      </div>
                      <div className="accord-content">
                        <p>
                          You can pay for your subscription through our pay bill
                          number <span style={{color:"#48bdb4"}}>4079943</span>, then input your account number.
                        </p>
                      </div>
                    </div>

                    <div
                      className="accordion accordion-block last-child wow fadeInUp"
                      data-wow-delay="300ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="accord-btn">
                        <h4>What is your customer care support line?</h4>
                      </div>
                      <div className="accord-content">
                        <p>
                          For any help, you can call our customer care support
                          line, +254795223223. You can also write to us at
                          info@hmiwirelessltd.com.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Faq;

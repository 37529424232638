import React from "react";
import Footer from "../footer/Footer";
import Pricingtable from "../pricingtable/Pricingtable";
import "animate.css";
import Navbar from "../navbar/Navbar";
import Faq from "../faq/Faq"

class Packages extends React.Component {
  render() {
    return (
      <div>
        <Navbar />
        {/* breadcrumb */}
        <section
          className="breadcrumb-area"
          style={{
            backgroundImage: "url(/images/packages/packages_breadcrumb.jpeg)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="inner-content text-center clearfix">
                  <div className="title animate__animated animate__zoomIn">
                    <h1>Packages</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* end upload breadcrumb */}

        {/* pricing section */}
        <Pricingtable />
        {/* end pricing section */}

        {/*  */}
        <section className="offering-area">
          <div className="container  animate__animated animate__fadeInUp">
            <div className="sec-title text-center">
              <h6>What else we are offering</h6>
              <div className="title">Included in All Our Packages</div>
            </div>
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="single-offering-box">
                  <span className="flaticon-quality"></span>
                  <h4>
                    Quality Internet
                    <br /> Bandwidths
                  </h4>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="single-offering-box">
                  <span className="flaticon-download-from-the-cloud"></span>
                  <h4>
                    Unlimited
                    <br /> Downloads
                  </h4>
                </div>
              </div>

              {/* <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <div className="single-offering-box">
                  <span className="flaticon-calendar"></span>
                  <h4>
                    Flexible Contract
                    <br /> Lengths
                  </h4>
                </div>
              </div> */}

              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="single-offering-box">
                  <span className="flaticon-support"></span>
                  <h4>
                    Professional
                    <br /> Support
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  */}

        {/* faq */}
        <Faq/>
        {/* end faq */}

        <Footer />
      </div>
    );
  }
}

export default Packages;

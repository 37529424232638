import React from "react";
import "./layout.css";
import "animate.css";

class Landing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      videoURL: "https://media.istockphoto.com/videos/aerial-view-of-big-city-in-africa-with-big-buildings-video-id1164421603",
    };
  }

  render() {
    return (
      <div>
        <section className="main-slider">
          <div
            id="carousel-example-generic"
            class="carousel slide"
            data-ride="carousel"
          >
            <div class="carousel-inner" role="listbox">
              <div class="item active">
                <img
                  class="img-responsive"
                  src="/images/home/home_landing.jpg"
                  alt=""
                  style={{
                    height: "100vh",
                    width: "100%",
                    backgroundImage: "url(/images/home/home_landing.jpg)",
                    backgroundRepeat: "no-repeat",
                    objectFit: "cover",
                  }}
                />
                {/* <video id="background-video" loop autoPlay muted
                style={{
                  height: "100vh",
                  width: "100%",
                  backgroundImage: "url(/images/home/home_landing.jpg)",
                  backgroundRepeat: "no-repeat",
                  height: "100vh",
                  objectFit: "cover",
                }}>
                  <source src={this.state.videoURL} type="video/mp4" />
                  <source src={this.state.videoURL} type="video/ogg" />
                  Your browser does not support the video tag.
                </video> */}

                <div class="container">
                  <div class="carousel-centered">
                    <div class="tp-caption">
                      <div className="slide-content left-slide">
                        <div className="text animate__animated animate__fadeInLeft">
                          From <span>Kes 2000</span> a month
                        </div>
                      </div>
                      <div className="slide-content left-slide">
                        <div className="big-title2 animate__animated animate__fadeInLeft">
                          <p>
                            Explore Over World
                            <br /> <span>Fibre.</span>
                          </p>
                        </div>
                      </div>

                      <div className="slide-content left-slide">
                        <div className="btn-box">
                          <a
                            className="btn-one animate__animated animate__fadeInLeft"
                            href="#home-about"
                          >
                            Discover More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Landing;

import React from "react";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";

class Iot extends React.Component {
  render() {
    return (
      <div>
        <Navbar />
        {/* about breadcrumb */}
        <section
          className="breadcrumb-area"
          style={{
            backgroundImage: "url(/images/services/Iot_solutions.jpeg)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="inner-content text-center clearfix">
                  <div className="title">
                    <h1>IOT Solutions</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* end upload breadcrumb */}

        {/* fleet mgt */}
        <section className="welcome-area secpd1">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="choose-image-box">
                            <img src="/images/services/iot/fleet.jpg"
                                alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="">
                            <div className="sec-title">
                                <div className="title">Fleet management</div>
                            </div>
                            <div className="inner-content">
                                <div className="text">
                                    <p>Fleet management is used to describe vehicle onboard communication services and
                                        applications
                                        that communicate with one another via GPS receivers and other telematics
                                        devices.</p>
                                    <p>Our Fleet management system aims to maximize efficiency, increase productivity
                                        and improve
                                        safety for an organization's vehicles and drivers.</p>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        {/* end fleet mgt */}

        {/* energy */}
        <section className="welcome-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="">
                            <div className="sec-title">
                                <div className="title">Energy and Utilities</div>
                            </div>
                            <div className="inner-content">
                                <div className="text">
                                    <p>The demand for smart meters is growing worldwide, yet it must address critical
                                        issues sooner rather than later for the industry to reach its potential.</p>
                                    <p>Tai Fibre smart meters meet both these demands, yet they also introduce a wealth
                                        of serious
                                        security
                                        issues – from privacy problems to state-sponsored cyber-attacks. Smart meter
                                        security and
                                        reliable connectivity are, therefore, paramount if smart meters are to become
                                        truly
                                        widespread.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="choose-image-box">
                            <img src="/images/services/iot/energy&utilities.jpg" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* end energy */}

        <Footer/>
      </div>
    );
  }
}

export default Iot;

import React from "react";
import Footer from "../footer/Footer";
import "./blog.css";
// import CountDownTimer from "./CountDownTimer";
import Navbar from "../navbar/Navbar";


class Blog extends React.Component {
  render() {
    // const hoursMinSecs = { hours: 24, minutes: 59, seconds: 59 };
    return (
      <div>
        <Navbar />
        {/* breadcrumb */}
        <section
          className="breadcrumb-area"
          style={{
            backgroundImage: "url(/images/blog/blog_bc.jpg)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="inner-content text-center clearfix">
                  <div className="title animate__animated animate__zoomIn">
                    <h1>Blog</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* end breadcrumb */}

        {/* breadcrumb */}
        <section
          className="breadcrumb-area"
          style={{
            backgroundColor: "#000",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="inner-content text-center clearfix">
                  <div className="title animate__animated animate__bounceIn">
                  {/* <p>Blog posts coming soon. Stay tuned!</p> */}
                    <h2>
                      {/* <CountDownTimer hoursMinSecs={hoursMinSecs} /> */}
                      Blog posts coming soon. Stay tuned!
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* end upload breadcrumb */}

        <Footer />
      </div>
    );
  }
}

export default Blog;

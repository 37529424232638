import React from "react";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";

class Security extends React.Component {
  render() {
    return (
      <div>
        <Navbar />
        {/* about breadcrumb */}
        <section
          className="breadcrumb-area"
          style={{
            backgroundImage: "url(/images/services/security_solutions.jpg)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="inner-content text-center clearfix">
                  <div className="title">
                    <h1>Security Solutions</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* end upload breadcrumb */}

        {/* video surveillance */}
        <section className="welcome-area secpd1">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="choose-image-box">
                            <img src="/images/services/security/cctv.jpeg"
                                alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="">
                            <div className="sec-title">
                                
                                <div className="title">Video Surveillance</div>
                            </div>
                            <div className="inner-content">
                                <div className="text">
                                    <p>Tai fibre connect represents one of the leading CCTV equipment Installation supplies
                                        Company in and
                                        Kenya offering outstanding quality, industry
                                        leading warranty periods and technological innovation that meets any
                                        specification and
                                        budget.</p>
                                    <p>With the ability to supply and install virtually any type of observation system,
                                        Tai Fiber has
                                        demonstrated the capacity to meet the most
                                        demanding requirements in almost every industry segment across the country, from
                                        government
                                        offices to remote surveillance of cell-towers and Automatic Banking Machines.
                                    </p>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        {/* end video surveillance */}

        {/* biometrics */}
        <section className="welcome-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="">
                            <div className="sec-title">
                                
                                <div className="title">Biometrics Solutions</div>
                            </div>
                            <div className="inner-content">
                                <div className="text">
                                    <p>The demand for smart meters is growing worldwide, yet it must address critical
                                        issues sooner rather than later for the industry to reach its potential.</p>
                                    <p>Biometrics and multi-factor authentication have emerged as the gold standard when
                                        confirming
                                        identity.
                                        Aware identity verification and management solutions come into play in a variety
                                        of
                                        environments, from
                                        law enforcement and healthcare to financial services and on-site enterprise
                                        security.</p>
                                    <p>We offer biometrics solutions based on the customer specifications and segments
                                        in the
                                        market.
                                        These are face recognition, fingerprint scanners, and retinal scanning.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="choose-image-box">
                            <img src="/images/services/security/biometric_scanner.jpg"
                                alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* end biometrics */}

        <Footer/>
      </div>
    );
  }
}

export default Security;

import React from "react";

class Pricingtable extends React.Component {
  render() {
    return (
      <div>
        <section className="services-style1-area" id="pricing-area">
          <div className="container-fluid">
            <div className="container">
              <div
                className="sec-title text-center animate__animated animate__fadeInUp"
                style={{ paddingTop: "100px" }}
              >
                <h6>Get the best Internet packages</h6>
                <div className="title">Our Packages</div>
              </div>
              <div className="row">
                <div className="col-lg-3 animate__animated animate__fadeInUp">
                  <div className="card text-center">
                    <div className="title">
                      <i className="fa fa-paper-plane" aria-hidden="true"></i>
                      <h2>Lite</h2>
                    </div>
                    <div className="price">
                      <h4>
                        Kes 2000.00/mo
                        <sup />
                      </h4>
                    </div>
                    <div className="option">
                      <ul>
                        <li>
                          <i className="fa fa-check" aria-hidden="true"></i> Up
                          to 5Mbps fibre.
                        </li>
                        <li>
                          <i className="fa fa-check" aria-hidden="true"></i>
                          Made for 1-3 devices.
                        </li>
                        <li>
                          <i className="fa fa-check" aria-hidden="true"></i>
                          Live Support
                        </li>
                      </ul>
                    </div>
                    <p>Unlimited Internet </p>
                  </div>
                </div>
                <div className="col-lg-3 animate__animated animate__fadeInUp">
                  <div className="card text-center">
                    <div className="title">
                      <i className="fa fa-plane" aria-hidden="true"></i>
                      <h2>Pro</h2>
                    </div>
                    <div className="price">
                      <h4>
                        Kes 2500.00/mo
                        <sup />
                      </h4>
                    </div>
                    <div className="option">
                      <ul>
                        <li>
                          <i className="fa fa-check" aria-hidden="true"></i> Up
                          to 10Mbps fibre.
                        </li>
                        <li>
                          <i className="fa fa-check" aria-hidden="true"></i>
                          Made for 2-5 devices.
                        </li>
                        <li>
                          <i className="fa fa-check" aria-hidden="true"></i>
                          Live Support
                        </li>
                      </ul>
                    </div>
                    <p>Unlimited Internet </p>
                  </div>
                </div>
                <div className="col-lg-3 animate__animated animate__fadeInUp">
                  <div className="card text-center">
                    <div className="title">
                      <i className="fa fa-rocket" aria-hidden="true"></i>
                      <h2>Max</h2>
                    </div>
                    <div className="price">
                      <h4>
                        Kes 3800.00/mo
                        <sup />
                      </h4>
                    </div>
                    <div className="option">
                      <ul>
                        <li>
                          <i className="fa fa-check" aria-hidden="true"></i> Up
                          to 15Mbps fibre.
                        </li>
                        <li>
                          <i className="fa fa-check" aria-hidden="true"></i>
                          Made for multiple devices.
                        </li>
                        <li>
                          <i className="fa fa-check" aria-hidden="true"></i>
                          Live Support
                        </li>
                      </ul>
                    </div>
                    <p>Unlimited Internet </p>
                  </div>
                </div>
                <div className="col-lg-3 animate__animated animate__fadeInUp">
                  <div className="card text-center">
                    <div className="title">
                      <i className="fa fa-bolt" aria-hidden="true"></i>
                      <h2>Premium</h2>
                    </div>
                    <div className="price">
                      <h4>
                        Kes 5000.00/mo
                        <sup />
                      </h4>
                    </div>
                    <div className="option">
                      <ul>
                        <li>
                          <i className="fa fa-check" aria-hidden="true"></i> Up
                          to 20Mbps fibre.
                        </li>
                        <li>
                          <i className="fa fa-check" aria-hidden="true"></i>
                          Made for multiple devices.
                        </li>
                        <li>
                          <i className="fa fa-check" aria-hidden="true"></i>
                          Live Support
                        </li>
                      </ul>
                    </div>
                    <p>Unlimited Internet </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Pricingtable;
